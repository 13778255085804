/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import Page from "../layouts/page";
import { Link } from "gatsby";

const hero = css`
font-size: 4em;
font-weight: normal;
`

const rapidIxSpan = css`
color: #44718E;
`

const byline = css`
font-weight: lighter;
font-size: 2em;
`

const IndexPage = () => {
    return <Page>
        <h1 css={hero}>Welcome to <span css={rapidIxSpan}>RapidIX</span></h1>
        <p css={byline}>RapidIX is an <a href="https://en.wikipedia.org/wiki/Internet_exchange_point">Internet exchange point</a> present at <a href="https://www.interxion.com/uk/locations/europe/london/lon1">Interxion LON1</a>.</p>

        <h3>What is an Internet Exchange Point?</h3>

        <p>An Internet Exchange Point (IXP) is a location where <a href="https://en.wikipedia.org/wiki/Autonomous_system_(Internet)">autonomous systems</a> are able to interconnect and exchange Internet traffic. By utilising IXPs, network operators are able to decrease dependency on upstream transit, reducing operating costs and improving network quality.</p>

        <h3>How to join RapidIX?</h3>

        <p>Joining RapidIX is simple, we're an open exchange meaning that networks of any size are welcome to connect. Head over to our <Link to="/get-connected">Get Connected</Link> page for instructions on becoming a RapidIX member.</p>
    </Page>
}

export default IndexPage
